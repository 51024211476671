import { Box, Grid, makeStyles } from "@material-ui/core";
import React from "react";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import NavigateBeforeIcon from "@material-ui/icons/NavigateBefore";
import cx from "classnames";

import OptionsGroup from "../../components/OptionsGroup";
import {
  loanFeatureOptions,
  loanFeaturesAcknowledgementOptions,
} from "../../../../commons/loan-application-constants";
import { useStyles as useStepStyles } from "../../../../utils/loan-application.styles";
import Button from "../../components/Button";

const useStyles = makeStyles((theme) => ({
  marginTop48OnMobile: {
    [theme.breakpoints.down("xs")]: {
      marginTop: "48px",
    },
  },
}));

const LoanFeaturesStep = ({ formik, handleNextStep, handlePrevStep }) => {
  const classes = useStyles();
  const stepClasses = useStepStyles();

  return (
    <Box display="flex" flexDirection="column" gridRowGap="48px">
      <Grid container spacing={6} xs={12} sm={6}>
        <OptionsGroup
          title="Do you know what loan features you want? Example, offset account, redraw ect"
          options={loanFeaturesAcknowledgementOptions}
          selectedValue={formik.values.loanQuiz.loanFeaturesAcknowledge}
          onSelect={(value) =>
            formik.setFieldValue("loanQuiz.loanFeaturesAcknowledge", value)
          }
        />
      </Grid>
      {formik.values.loanQuiz.loanFeaturesAcknowledge === "yes" && (
        <Grid container spacing={6} xs={12} sm={6}>
          <OptionsGroup
            title="What features are important to you?"
            options={loanFeatureOptions}
            selectedValue={formik.values.loanQuiz.loanFeature}
            onSelect={(value) =>
              formik.setFieldValue("loanQuiz.loanFeature", value)
            }
          />
        </Grid>
      )}
      <Grid
        container
        xs={12}
        sm={6}
        justifyContent="space-between"
        classes={{
          root: cx(
            stepClasses.navigationButtonsContainer,
            classes.marginTop48OnMobile
          ),
        }}
      >
        <Button startIcon={<NavigateBeforeIcon />} onClick={handlePrevStep}>
          Previous
        </Button>
        <Button
          endIcon={<NavigateNextIcon />}
          onClick={handleNextStep}
          disabled={
            formik.values.loanQuiz.loanFeaturesAcknowledge === "" ||
            (formik.values.loanQuiz.loanFeaturesAcknowledge === "yes" &&
              formik.values.loanQuiz.loanFeature === "")
          }
        >
          Next
        </Button>
      </Grid>
    </Box>
  );
};

export default LoanFeaturesStep;
